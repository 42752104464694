@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('./utils/variables.css');

@font-face {
    font-family: 'Moglite';
    src: url('./utils/Mogilte.otf');
}

/* GLYPHS: https://www.dafont.com/mogilte.charmap?text=%26%2361447%3B%26%2361447%3B%26%2361447%3BSzanda%26%23322%3Ba */

* {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 10px;
}
